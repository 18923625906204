export const featureFlags = {
    role: {
        admin: {
            feature: {
                //Reports
                depositReport: true,
                withdrawReport: true,
                accountsReport: true,
                tradesReport: true,
                //Deposit and Withdraw
                deposit: true,
                withdraw: true,
                //Revio
                depositRevio: true,
                withdrawRevio: true,
                //Coinsbuy
                depositCoinsbuy: true,
                withdrawCoinsbuy: true,
                //TcPay
                depositTcPay: true,
                withdrawTcPay: true,
                //Yellowcard
                depositYellowcard: true,
                withdrawYellowcard: true,
            }
        },
        user: {
            feature: {
                //Reports
                depositReport: false,
                withdrawReport: false,
                accountsReport: false,
                tradesReport: false,
                //Deposit and Withdraw
                deposit: true,
                withdraw: true,
                //Revio
                depositRevio: true,
                withdrawRevio: true,
                //Coinsbuy
                depositCoinsbuy: true,
                withdrawCoinsbuy: true,
                //TcPay
                depositTcPay: false,
                withdrawTcPay: false,
            }
        },
    }
}