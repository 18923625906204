import { z, ZodFormattedError } from 'zod';

export const clientSchema = z.object({
    REACT_APP_BASE_API_URL: z.string().min(1),
    REACT_APP_AUTH0_DOMAIN: z.string().min(1),
    REACT_APP_AUTH0_CLIENTID: z.string().min(1),
    REACT_APP_AUTH0_AUDIENCE: z.string().min(1),
    REACT_APP_SENTRY_DSN: z.string().min(1),
    REACT_APP_ENV: z.string().min(1),
    REACT_APP_GEOAPIFY_API_KEY: z.string().min(1),
    REACT_APP_RUDDERSTACK_DATA_PLANE_URL: z.string().min(1),
    REACT_APP_RUDDERSTACK_WRITE_KEY: z.string().min(1),
    REACT_APP_TRANSIFEX_TOKEN: z.string().min(1),
    REACT_APP_TC_PAY_URL: z.string().min(1),
    REACT_APP_YELLOWCARD_API_KEY: z.string().min(1),
});

const _clientEnv = clientSchema.safeParse(process.env);

export const formatErrors = (errors: ZodFormattedError<Map<string, string>, string>) =>
    Object.entries(errors)
        .map(([name, value]) => {
            if (value && '_errors' in value) return `${name}: ${value._errors.join(', ')}\n`;
        })
        .filter(Boolean);

if (!_clientEnv.success) {
    console.error(
        '❌ Invalid environment variables:\n',
        ...formatErrors(_clientEnv.error.format()),
    );
    throw new Error('Invalid environment variables');
}

export enum AppEnvs {
    LOCAL = 'local',
    DEV = 'development',
    STAGE = 'staging',
    PROD = 'production',
}

export const env = _clientEnv.data;
